.app {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app--container {
  position: relative;
}

.app--container::before {
  position: absolute;
  content: "";
  width: 5px;
  height: 100%;
  top: 0;
  left: -20px;
  background-color: #FFFFFF;
}

.app--title {
  line-height: 7vw;
  font-size: 10vw;
  margin-bottom: 30px;
}

.link--148:hover {
  color: #fbc450;
}
