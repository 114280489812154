@import url(https://fonts.googleapis.com/css2?family=Karla&display=swap);
* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: 'Karla', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #262626;
  color: #FFFFFF;
}

p {
  letter-spacing: 0.5px;
}

a {
  font-weight: bold;
  text-decoration: none;
  color: #FFFFFF;
  transition: color ease 400ms;
}

a:hover,
a:focus {
  color: #3e53bb;
}


.app {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app--container {
  position: relative;
}

.app--container::before {
  position: absolute;
  content: "";
  width: 5px;
  height: 100%;
  top: 0;
  left: -20px;
  background-color: #FFFFFF;
}

.app--title {
  line-height: 7vw;
  font-size: 10vw;
  margin-bottom: 30px;
}

.link--148:hover {
  color: #fbc450;
}

